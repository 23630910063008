import { Colors, Gradients } from "../styles/colors";
import ContactLink from "../components/contactLink";

let contactsPageStyle = {
  backgroundColor: Colors.dark,
  height: "100vh",
  width: "100vw",
  backgroundSize: "cover",
  alignContent: "center",
};

let contactPageLinksStyle = {
  paddingBottom: "15vh",
};

const ContactPage = () => (
  <div id="contactsPage" style={contactsPageStyle}>
    <div style={contactPageLinksStyle}>
      <ContactLink name="Email" linkText="Caitlynmilne2001@gmail.com" />
      <ContactLink
        name="Linkedin"
        link="https://linkedin.com/in/caitlyn-milne-b210a1193"
        linkText="linkedin.com/in/caitlyn-milne-b210a1193"
      />
      <ContactLink
        name="Github"
        link="https://github.com/Caitlyn-Milne"
        linkText="github.com/Caitlyn-Milne"
      />
    </div>
  </div>
);

export default ContactPage;
