import { Color } from "p5";
import { Colors } from "./colors";

const textStyle = {
  color: Colors.light,
  fontSize: "calc(0.8em + 0.2vmax)",
  fontWeight: 300,
};

const h1Style = {
  fontSize: "calc(1em + 4vmax)",
  fontWeight: 550,
};

const h2Style = {
  fontSize: "calc(1em + 2.5vmax)",
  fontWeight: 400,
};

const h3Style = {
  fontSize: "calc(1em + 1vmax)",
  fontWeight: 350,
  opacity: 0.8,
};

const boldStyle = {
  ...textStyle,
  fontWeight: 550,
};

const linkStyle = {
  ...boldStyle,
  color: Colors.primary,
  fontWeight: 300,
};

export { h1Style, h2Style, h3Style, textStyle, boldStyle, linkStyle };
