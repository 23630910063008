import { Colors, Gradients } from "../styles/colors";
import { Component } from "react";
import JobSection from "../components/jobSection";

let experiencePageStyle = {
  backgroundColor: Colors.dark,
  height: "100vh",
  width: "100vw",
  backgroundSize: "cover",
  textAlign: "center",
};

let experiencePageJobSectionStyle = {
  paddingTop: "10vh",
  paddingBottom: "10vh",
  height: "80vh",
  display: "grid",
};

const ExperiencePage = () => (
  <div style={experiencePageStyle}>
    <div style={experiencePageJobSectionStyle}>
      {" "}
      <JobSection
        title="Unity Technologies"
        skills={["C++", "C#", "Unity", "Windows", "Xbox"]}
        startDate="Mar 2023"
        endDate="Current"
      />
      <JobSection
        title="C Software"
        skills={["Kotlin", "C#", "Android", "WPF", "Web (Server)"]}
        startDate="Mar 2022"
        endDate="Feb 2023"
      />
      <JobSection
        title="Rift Technologies"
        skills={[
          "Java",
          "PHP",
          "C",
          "JS",
          "Android",
          "Atmel",
          "Web (Client)",
          "Web (Server)",
        ]}
        startDate="Aug 2018"
        endDate="Feb 2022"
      />
    </div>
  </div>
);

export default ExperiencePage;
