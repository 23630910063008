import { Component } from "react";
import { Colors } from "../styles/colors";
import { textStyle, boldStyle, linkStyle } from "../styles/text";
import MyButton from "./myButton";

let contentLinkStyle = {
  backgroundColor: Colors.dark,
  verticalAlign: "middle",
  textAlign: "left",
  margin: "1vh 10vmin",
};

let ContactLinkNameStyle = {
  ...boldStyle,
  fontSize: "calc(1em + 1vmax)",
};

let ContactLinkLinkStyle = {
  ...linkStyle,
  fontSize: "calc(1em + 1vmax)",
};

export default class ContactLink extends Component {
  constructor(props) {
    super(props);

    this.name = props.name;

    if (props.link != null) {
      this.link = props.link;
    } else {
      this.link = "javascript:void(0)";
    }

    this.linkText = props.linkText;
  }

  render() {
    return (
      <div style={contentLinkStyle}>
        <span style={ContactLinkNameStyle}>{this.name}</span> :{" "}
        <a style={ContactLinkLinkStyle} href={this.link}>
          {this.linkText}
        </a>
      </div>
    );
  }
}
